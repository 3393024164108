import React from 'react'
import { Link } from 'react-router-dom'
// import { YMaps } from 'react-yandex-maps';

import emailsvg from '../../assets/img/email.svg'
import pindrop from '../../assets/img/pin_drop.svg'
import phonealt from '../../assets/img/phone-alt.svg'

import './Index.css'

export default function Index() {
  return (
    <div style={{
      flexGrow: 1
    }}>
      <div className="ofices-section page-wrapper">
        <div className="page-top about-wrapper_center">
          <h2 className="page-title">
            Контакты
          </h2>
          <p className="breadcrumbs">
            <Link to="/">Главная</Link>/контакты
          </p>
        </div>
        <div className="row">
          <div className="col-xl-6 mb-5">
            <div className="info_wrapper">
              <div className="info__text">
                <p>
                  Есть вопросы? Свяжитесь с нами, и наши консультанты с
                  радостью Вам ответят
                </p>
              </div>
              <div className="info__phone">
                <ul className="info__contact">
                  <li>
                    <img src={phonealt} className="info__img" alt=""/>
                    <p className="info__phone-text">
                      <a href="tel:+992987229000"> +992 98-722-90-00</a>
                    </p>
                  </li>
                  <li>
                    <img src={phonealt} className="info__img" alt=""/>
                    <p className="info__phone-text">
                      <a href="tel:+992110220000"> +992 11-022-00-00</a>
                    </p>
                  </li>
                  <li>
                    <img src={emailsvg} className="info__img" alt=""/>
                    <p className="info__phone-text">
                      <a href="mailto:info@armon.tj">info@armon.tj</a>
                    </p>
                  </li>
                </ul>
              </div>
              <div className="info__text">
                <p>Основной офис продаж находится по адресу:</p>
              </div>
              <div className="info__address-text">
                <img src={pindrop} className="info__img img__adress" alt=""/>
                <p>
                  <a href="https://yandex.ru/maps/-/CDVu4EMj">
									г. Душанбе, Испечак 2, М14
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div
              className="info_wrapper_2"
              style={{position: 'relative', overflow: 'hidden'}}
            >
              <a
                href="https://yandex.ru/maps/10318/dushanbe/?utm_medium=mapframe&utm_source=maps"
                style={{
                  color: '#eee',
                  fontSize: 12,
                  position: 'absolute',
                  top: 0,
                }}
              >
                Душанбе
              </a>
              <a
                href="https://yandex.ru/maps/10318/dushanbe/geo/1926089001/?ll=68.730417%2C38.582082&utm_medium=mapframe&utm_source=maps&z=14"
                style={{
                  color: '#eee',
                  fontSize: 12,
                  position: 'absolute',
                  top: 14,
                }}
              >
                Испечак 2, М14 в городе Душанбе — Яндекс.Карты
              </a>
              <iframe
                src="https://yandex.ru/map-widget/v1/-/CDVqAD3t"
                width="100%"
                height="435px"
                frameBorder="0"
                allowFullScreen={true}
                style={{osition: 'relative'}}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
