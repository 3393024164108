import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Modal from '../modal/Index'
import operator from '../../assets/img/operator1.svg'
import Group from '../../assets/img/Group.svg'
import frames from '../../assets/img/Frame.svg'
import './Banner.css'
import BannerItem from './BannerItem/BannerItem'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import ReactHtmlParser from 'react-html-parser'
import Moment from 'react-moment'

const responsive = {
  desktop: {
    breakpoint: {max: 3000, min: 1024},
    items: 3,
    partialVisibilityGutter: 10, // this is needed to tell the amount of px that should be visible.
    infinite: false
  },
  tablet: {
    breakpoint: {max: 1024, min: 464},
    items: 2,
    partialVisibilityGutter: 10, // this is needed to tell the amount of px that should be visible.
    centerMode: true,
    infinite: false
  },
  mobile: {
    breakpoint: {max: 464, min: 0},
    items: 2,
    partialVisibilityGutter: 10 // this is needed to tell the amount of px that should be visible.
  }
}

export default function Banner() {
  const [modalShow, setModalShow] = useState(false)
  const [banners, setBanners] = useState([])
  const history = useHistory()
  const payment = () => {
    history.push('/payment')
  }
  const tradein = () => {
    history.push('/tradein')
  }

  useEffect(() => {
    setBanners([
      {
        id: 'payment',
        title: 'Оплата',
        text: 'от застройщика',
        mobileText: 'от застройщика',
        src: Group,
        func: payment
      },
      {
        id: 'consultation',
        title: 'Консультация',
        text: 'получите ответы на вопросы',
        mobileText: 'Ответы на вопросы',
        src: operator,
        func: () => setModalShow(true)
      },
      {
        id: 'trade',
        title: 'Trade-in',
        text: 'На выгодных условиях',
        // mobileText: 'На выгодных условиях',
        mobileText: 'выгодных условиях',
        src: frames,
        func: tradein
      },
    ])
  }, [])

  return (
    <div className="banner-section">
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <div className="projects-wrapper">
        <div className="row">
          <div className="banner__carousel">
            <Carousel
              responsive={responsive}
              swipeable={true}
              draggable={true}
              showDots={false}
              ssr={true} // means to render carousel on server-side.
              infinite={false}
              autoPlay={false}
              keyBoardControl={true}
              transitionDuration={500}
              containerClass="banner-container"
              removeArrowOnDeviceType={['tablet', 'mobile']}
              deviceType={true}
              dotListClass="custom-dot-list-style"
              itemClass="banner-carousel-item"
            >
              {banners.map((banner, index) =>
                <BannerItem key={index} {...banner} />
              )}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  )
}
