import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Toast } from "react-bootstrap";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

import ScrollToTop from "react-router-scroll-top";

import "./Index.css";
import Imglayouts from "../../assets/img/layouts-1.jpg";
import Rodal from "rodal";

// include styles
import "rodal/lib/rodal.css";
import icon1 from "../../assets/icons/1.svg";
import icon2 from "../../assets/icons/2.svg";
import icon3 from "../../assets/icons/3.svg";
import mf1 from "../../assets/icons/mf1.svg";
import mf2 from "../../assets/icons/mf2.svg";
import mf3 from "../../assets/icons/mf3.svg";
import lybtn from "../../assets/icons/ly-btn-e.svg";
import lyiconh from "../../assets/icons/ly-icon-h.svg";
import http from "../../http";
import validation from "./validateModal";

export default function Index({ post, stylePaginate, towers, setTowers }) {
  const [layout, setLayot] = useState(null);
  const [activeBtn, setActiveBtn] = useState(false);
  const [activeBtn1, setActiveBtn1] = useState(false);
  const [activeBtn2, setActiveBtn2] = useState(true);
  const [activeBtn3, setActiveBtn3] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [showNotif, setShowNotif] = useState(false);
  const [modalfShow, setModalfShow] = useState(false);
  const [validetAlert, setvalidetAlert] = useState("");
  const [residences, setResidences] = useState([]);
  const [applications, setApplications] = useState({
    id: null,
    username: "",
    phone: "",
  });
  const [jkdesc, setJkdesc] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const activate = (a, b, c, d) => {
    if (a !== false) {
      setActiveBtn(true);
      setActiveBtn1(false);
      setActiveBtn2(false);
      setActiveBtn3(false);
    } else if (b !== false) {
      setActiveBtn(false);
      setActiveBtn1(true);
      setActiveBtn2(false);
      setActiveBtn3(false);
    } else if (c !== false) {
      setActiveBtn(false);
      setActiveBtn1(false);
      setActiveBtn2(true);
      setActiveBtn3(false);
    } else if (d !== false) {
      setActiveBtn(false);
      setActiveBtn1(false);
      setActiveBtn2(false);
      setActiveBtn3(true);
    }
  };

  const [modalSImg, setModalSImg] = useState([]);

  const getImg = (el) => {
    setModalSImg(el);
    setJkdesc([el]);
  };

  const onsales = post;
  // ?.filter((e) => {
  //   if (!layout || layout === null) {
  //     return e
  //   } else if (e.residences.toLowerCase().includes(layout.toLowerCase())) {
  //     return e
  //   }
  // })

  useEffect(() => {
    http
      .get("get_residences")
      .then((res) => {
        setResidences(res.data);
      })
      .catch((errors) => {
        console.log("Ошибка", `${errors.message}`);
      });
  }, []);

  const [openImgModal, setOpenImgModal] = useState({ visible: false });

  const [openFormModal, setOpenFormModal] = useState({ visible: false });

  const [firstPZ, setFirstPZ] = useState(false);
  const show = () => {
    setOpenImgModal({ visible: true });
    setFirstP(false);
  };

  const hide = () => {
    setOpenImgModal({ visible: false });
    setFirstPZ(true);
  };

  const showMForm = () => {
    setOpenFormModal({ visible: true });
  };

  const hideMForm = () => {
    setOpenFormModal({ visible: false });
  };

  const showFromjk = (el) => {
    const arr = [el];
    setJkdesc(arr);
    setApplications({ ...applications, id: el.id });
    showMForm();
    getImg(el);
  };

  // const [validetAlert, setValidetAlert] = useState(null)
  const handleModalInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const data = applications;
    data[name] = value;
    setApplications(data);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (!valideModal()) {
      return;
    }

    const data = new FormData();
    data.append("name", applications.username);
    data.append("phone", applications.phone);
    const tgl = new FormData();
    tgl.append("site", "armon.tj");
    tgl.append(
      "theme",
      `В продаже - ${jkdesc[0].floor} | ${jkdesc[0].rooms} | ${jkdesc[0].square} | ${jkdesc[0].residences}`
    );
    tgl.append("name", applications.username);
    tgl.append("phone", applications.phone);

    setIsSubmitting(true);

    try {
      const response = await axios.post(
        `https://armon.tj/freedom/telegramForm/php/send-message-to-telegram.php`,
        tgl
      );
      setvalidetAlert("Ваша заявка успешно отправлена!");
      setShowNotif(true);

      let text = `<b>Armon Development (armon.tj)</b>\n`;
      text += `<b>Заявка!</b>\n\n`;
      text += `<b>Имя:</b> ${applications.username}\n`;
      text += `<b>Контакт:</b> ${applications.phone}\n`;
      text += `<b>В продаже:</b> ${jkdesc[0].floor} | ${jkdesc[0].rooms} | ${jkdesc[0].square} | ${jkdesc[0].residences}\n`;

      await fetch(
        `https://api.telegram.org/bot7078157268:AAGjx0_82EKNlH0DxKYwm-4sTOT2499lGhY/sendMessage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            chat_id: -1002104540616,
            parse_mode: "html",
            text,
          }),
        }
      );

      await http.post(`create_order/${applications.id}`, data);
      setApplications({
        id: null,
        username: "",
        phone: "",
      });
      hideMForm();
    } catch (error) {
      console.error("Ошибка", error.message);
    } finally {
      setIsSubmitting(false);
    }
  };
  // jovid1242jivO

  const [firstPX, setFirstPX] = useState(0);
  const [firstPY, setFirstPY] = useState(143);
  const [firstP, setFirstP] = useState(false);

  const rest = () => {
    setFirstPX(0);
    setFirstPY(143);
  };

  const valideModal = () => {
    const err = validation.modalValidation(applications);
    if (err.error) {
      setvalidetAlert(err.message);
      setShowNotif(true);
      return false;
    }
    return true;
  };
  // useEffect(() => {
  //   const wrapper = document.querySelector("react-transform-component");
  //   console.log("wrapper", wrapper);
  //   wrapper.classList.add("active");
  // }, []);

  // function active() {
  //   const nav = document.querySelector(".react-transform-component");
  //   if (firstPZ) {
  //     nav.classList.add("firstp");
  //   }
  // }

  // const { setTransform } = transformComponentRef.current.context.dispatch;
  // window.requestAnimationFrame(() => {
  //   setTransform(
  //     0 - x + width / 2,
  //     0 - y + height / 2,
  //     scale,
  //     animationTime,
  //     animationType
  //   );
  // });

  const manageKeyPress = (funcs) => {
    var { zoomIn, zoomOut, resetTransform } = funcs;
    // console.log("rr", resetTransform);
    if (firstP) {
      resetTransform();
    }
  };

  return (
    <ScrollToTop>
      <div className="sales-section page-wrapper">
        <div className="layouts-modal__wrapper">
          <div>
            <Rodal
              visible={openImgModal.visible}
              onClose={() => {
                hide();
                setFirstP(true);
              }}
            >
              <TransformWrapper
                defaultScale={1}
                defaultPositionX={firstPX}
                defaultPositionY={firstPY}
              >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <React.Fragment>
                    <div className="tools">
                      <button onClick={zoomIn} className="tol__button">
                        <FontAwesomeIcon icon={faPlus} color="white" />
                      </button>
                      <button onClick={zoomOut} className="tol__button">
                        <FontAwesomeIcon icon={faMinus} color="white" />
                      </button>
                      <button onClick={resetTransform} className="tol__button">
                        <div
                          className=""
                          onKeyDown={manageKeyPress({
                            zoomIn,
                            zoomOut,
                            resetTransform,
                          })}
                        ></div>
                        <FontAwesomeIcon icon={faUndo} color="white" />
                      </button>
                      <button onClick={showMForm.bind()} className="jk__form">
                        Оставить заявку
                      </button>
                    </div>
                    <TransformComponent>
                      <img
                        src={modalSImg.img}
                        className="img__trasformw"
                        alt="big"
                      />
                    </TransformComponent>
                  </React.Fragment>
                )}
              </TransformWrapper>
            </Rodal>
          </div>
        </div>
        <div className="layouts-form__modal__wrapper">
          <Rodal visible={openFormModal.visible} onClose={hideMForm.bind()}>
            <div className="wrapper-modal-window d-flex flex-column flex-lg-row  justify-content-lg-between align-items-center">
              <div className="wraper-modal-1">
                <h3>
                  {jkdesc?.map((el) => el.residences)}
                  <Toast
                    onClose={() => setShowNotif(false)}
                    show={showNotif}
                    delay={3000}
                    autohide
                  >
                    <Toast.Body>{validetAlert}</Toast.Body>
                  </Toast>
                </h3>
                <img src={modalSImg.img} alt="Img" />
              </div>
              <div className="wraper-modal-2">
                <div className="form__group">
                  {jkdesc?.map((el, index) => {
                    return (
                      <ul className="list__t" key={index}>
                        <li>
                          <div className="sales-wrapper-inner d-flex align-items-center ">
                            <img
                              className="sales-icon sales-icon2"
                              src={mf2}
                              alt="sdc"
                            />
                            <p className="floor">{el.floor}</p>
                          </div>
                        </li>
                        <li>
                          <div className="sales-wrapper-inner d-flex align-items-center ">
                            <img
                              className="sales-icon  sales-icon2"
                              src={mf3}
                              alt="sdc"
                            />
                            <p className="area">{el.square}</p>
                          </div>
                        </li>
                        <li>
                          <div className="sales-wrapper-inner d-flex align-items-center">
                            <img
                              className="sales-icon sales-icon2"
                              src={mf1}
                              alt="sdc"
                            />
                            <p className="room">{el.rooms}</p>
                          </div>
                        </li>
                      </ul>
                    );
                  })}
                  <div className="input__group">
                    <form action="" onSubmit={submitForm}>
                      <input
                        type="text"
                        name="username"
                        onChange={handleModalInput}
                        placeholder="Имя..."
                      />
                      <input
                        type="text"
                        name="phone"
                        onChange={handleModalInput}
                        className="midle-input"
                        placeholder="Телефон..."
                      />
                      <button
                        type="submit"
                        className="btn__form-group"
                        disabled={
                          isSubmitting ||
                          !applications.username ||
                          !applications.phone
                        }
                      >
                        {isSubmitting ? "Отправка..." : "Отправить"}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Rodal>
        </div>
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="img__jk-modal d-flex justify-content-center">
              <img className="sales-image" src={Imglayouts} alt="sdc" />
            </div>
          </Modal.Body>
        </Modal>

        <div className="page-top about-wrapper_center">
          <h2 className="page-title">В продаже</h2>
          <p className="breadcrumbs">
            <Link to="/">Главная</Link>/в продаже
          </p>
        </div>

        <div className="sales-filter">
          <ul className="sales-filter-list">
            <li>
              <button
                className={
                  !activeBtn2 ? "btns__filter" : "btns__filter activeBtn"
                }
                onClick={() => {
                  setLayot(null);
                  setActiveBtn(false);
                  setActiveBtn1(false);
                  activate(false, false, true, false);
                  stylePaginate(true);
                  setTowers(1);
                }}
              >
                Все ЖК
              </button>
            </li>
            <li>
              <button
                className={
                  !activeBtn1 ? "btns__filter" : "btns__filter activeBtn"
                }
                onClick={() => {
                  setLayot("Ispechak Residence");
                  activate(false, true, false, false);
                  stylePaginate(true);
                  setTowers(2);
                }}
              >
                ЖК Ispechak Residence
              </button>
            </li>
            <li>
              <button
                className={
                  !activeBtn ? "btns__filter" : "btns__filter activeBtn"
                }
                onClick={() => {
                  setLayot("ЖК Freedom Residence");
                  activate(true, false, false, false);
                  stylePaginate(false);
                  setTowers(3);
                }}
              >
                ЖК Freedom Residence
              </button>
            </li>
            <li>
              <button
                className={
                  !activeBtn3 ? "btns__filter" : "btns__filter activeBtn"
                }
                onClick={() => {
                  setLayot("Legend Towers");
                  activate(false, false, false, true);
                  stylePaginate(false);
                  setTowers(4);
                }}
              >
                ЖК Legend Towers
              </button>
            </li>
          </ul>
        </div>
        <div className="layouts-elements sales-elements">
          {onsales?.length === 0 ? (
            <div className="nosales">
              <h2>Все квартиры проданы</h2>
              <div className="mb__200"></div>
            </div>
          ) : (
            onsales?.map((el, index) => {
              return (
                <div key={index} className="layouts__card sales-card">
                  <div
                    className="img__hover"
                    onClick={() => {
                      getImg(el);
                    }}
                  >
                    <div className="sales-img-wrapper">
                      <img
                        className="sales-image"
                        src={el.img}
                        width={389}
                        height={239}
                        alt="sdc"
                        onClick={show.bind()}
                      />
                    </div>
                    <img
                      src={lyiconh}
                      className="icon-h"
                      alt="lyiconh"
                      onClick={show.bind()}
                    />
                  </div>
                  <div className="sales-card-info">
                    <p className="room">{el.rooms}</p>
                    <p className="text__w">
                      {towers == 1 ? el.residences : el.name}
                    </p>
                    <div className="d-flex mb-3 sales-subinfo">
                      <div className="sales-wrapper-inner d-flex align-items-center">
                        <img className="sales-icon" src={icon2} alt="" />
                        <p className="floor">{el.floor}</p>
                      </div>
                      <div className="sales-wrapper-inner d-flex align-items-center">
                        <img
                          className="sales-icon ml-1"
                          src={icon3}
                          alt="sdc"
                        />
                        <p className="area">{el.square}</p>
                      </div>
                    </div>
                    <button
                      className="sales-button"
                      onClick={() => {
                        showFromjk(el);
                      }}
                    >
                      Оставить заявку
                    </button>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </ScrollToTop>
  );
}
