import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import './Instagram.css'

export default function Instagram({post}) {
  const responsive = {
    desktop: {
      breakpoint: {max: 3000, min: 1024},
      items: 3,
      partialVisibilityGutter: 10 // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
      breakpoint: {max: 768, min: 464},
      items: 2,
      partialVisibilityGutter: 10 // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
      breakpoint: {max: 464, min: 0},
      items: 2,
      partialVisibilityGutter: 10 // this is needed to tell the amount of px that should be visible.
    }
  }
  return (
    <>
      <div className="top__insta">
        <div className="instagram-projects-header">
          <div className="projects-wrapper">
            <p>медиа</p>
            <div className="projects-inner">
              <span>Инстаграм</span>
            </div>
          </div>
        </div>
      </div>
      <div className="instagram">
        <div className="projects-wrapper">
          <div className="instagram-projects-cards">
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={false}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              transitionDuration={500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={['tablet', 'mobile']}
              deviceType={true}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-10-px"

            >
              {
                post.map((item, index) => {
                  return (
                    <a href={item.url} target="_blank" key={index}>
                      <div className="instagram-card">
                        <img src={item.img} alt=""/>
                      </div>
                    </a>
                  )
                })
              }
            </Carousel>
          </div>
        </div>
      </div>
    </>
  )
}
