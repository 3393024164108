import React, { useEffect, useState } from 'react'
import Carousel from 'react-grid-carousel'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-bootstrap'
import ReactPlayer from 'react-player/lazy'
import playBtn from '../../assets/img/play-button 1.svg'
import './Video.css'
import arrowLeft from '../../assets/img/arrow-left.svg'
import arrowRight from '../../assets/img/arrow-right.svg'

export default function Video({videoGallery}) {
  const [modalShow, setModalShow] = useState(false)
  const [urlModal, setUrlModal] = useState()
  const [state, setstate] = useState(2)

  useEffect(() => {
    if (window.screen.availWidth < 700) {
      setstate(2)
    }
  }, [])

  const handleVideo = (url) => {
    setUrlModal(url)
    setModalShow(true)
  }
  return (
    <>
      <div className="video-gallery">
        <div className="video-gallery-header">
          <div className="projects-wrapper">
            <p>медиа</p>
            <span>Наш ютуб</span>
          </div>
        </div>
        <div className="projects-wrapper">
          <div className="row">
            <Modal
              show={modalShow}
              onHide={() => setModalShow(false)}
              size="xl"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                {/* <h1 className="text-center text-loading my-auto">Загрузка...</h1> */}
                <ReactPlayer
                  playing={false}
                  controls={true}
                  className="react-player react-yt-pl overflow-hideen"
                  url={urlModal}
                  width="100%"
                  height="550px"
                />
              </Modal.Body>
            </Modal>
          </div>
          {/*<Carousel*/}
          {/*  cols={2}*/}
          {/*  rows={1}*/}
          {/*  dotColorActive={'#000'}*/}
          {/*  hideArrow={true}*/}
          {/*  showDots={false}*/}
          {/*  loop*/}
          {/*  responsiveLayout={[*/}
          {/*    {breakpoint: 1400, cols: 2},*/}
          {/*    {breakpoint: 768, cols: 2},*/}
          {/*    {breakpoint: 760, cols: 2},*/}
          {/*  ]}*/}
          {/*>*/}
          {/*  {videoGallery.map((item, index) => {*/}
          {/*    return (*/}
          {/*      <Carousel.Item key={index}>*/}
          {/*        <div className="video-container">*/}
          {/*          <img*/}
          {/*            data-toggle="modal"*/}
          {/*            className="image"*/}
          {/*            data-target="#exampleModalLong"*/}
          {/*            src={item.img}*/}
          {/*          />*/}
          {/*          <div className="middle">*/}
          {/*            <div className="play-btn">*/}
          {/*              <img*/}
          {/*                src={playBtn}*/}
          {/*                alt="play-btn"*/}
          {/*                onClick={() => {*/}
          {/*                  handleVideo(item.url)*/}
          {/*                }}*/}
          {/*              />*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*      </Carousel.Item>*/}
          {/*    )*/}
          {/*  })}*/}
          {/*</Carousel>*/}

          <div className="video-wrapper">
            {videoGallery.map((item, index) =>
              <div key={index} className="video-container">
                <img
                  data-toggle="modal"
                  className="image"
                  data-target="#exampleModalLong"
                  src={item.img}
                />
                <div className="middle">
                  <div className="play-btn">
                    <img
                      src={playBtn}
                      alt="play-btn"
                      onClick={() => {
                        handleVideo(item.url)
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
