import React from 'react'
import './BannerItem.css'

function BannerItem({id, func, src, title, text, mobileText}) {
  const types = {
    payment: 'wrapper_blue',
    consultation: 'wrapper_orange',
    trade: 'wrapper_green'
  }
  const cls = [
    'wrapper',
    types[id]
  ]
  return (
    <div className={`banner__item banner-${id}`}>
      <div className={cls.join(' ')} onClick={() => {
        {
          func()
        }
      }}>
        <img src={src} alt="" className="wrapper__img"/>
        <h4 className="wrapper-inner__title">
          {title}
        </h4>
        <p className="wrapper-inner__text" data-text={text} data-mobile-text={mobileText}></p>
      </div>
    </div>
  )
}

export default BannerItem