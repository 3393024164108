import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from '../modal/Index'

import './Index.css'

import btnsvg from '../../assets/img/t-inbtn.svg'
import imgtin from '../../assets/img/t-in.png'

export default function Index() {
    const [modalShow, setModalShow] = useState(false);
    return (
        <>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            <div className="payment-section page-wrapper">
                <div className="page-top about-wrapper_center">
                    <h2 className="page-title">
                        Tradee-in
                    </h2>
                    <p className="breadcrumbs">
                        <Link to="/">Главная</Link>\новости
                    </p>
                </div>
                <div className="payment-main-wrapper">
                    <div className="payment-main">
                        <div className="payment-wrapper">
                            <p className="payment__text-title">Взаимовыгодный обмен</p>
                            <div className="payment__text">
                                <p>Trade - in - схема не просто взаимозачётная, но и взаимовыгодная. Это редкая для обычного человека возможность приобрести квартиру в новостройке, не влезая ради неё в ипотеку. Это быстрый и удобный способ улучшить жилищные условия, в данной опции в качестве оплаты принимается автомобиль/квартира.</p>
                            </div>
                            <button
                              className="payment__btn"
                              onClick={() => setModalShow(true)}>
                                Оставить заявку
                            </button>
                        </div>
                        <div className="payment-img">
                            <img src={imgtin} alt="imgtin"/>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
