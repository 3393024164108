import React from 'react'
import './ButtonMore.css'

function ButtonMore({className, children, onClick}) {
  return (
    <button
      className={`button-more ${className ? className : ''}`}
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default ButtonMore