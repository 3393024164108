import React, { useState, useEffect } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import Main from '../components/layouts/Index';
import Pagination from '../components/pagination/Index';
import ScrollToTop from 'react-router-scroll-top';
import http from '../http';

export default function Team() {
  const [pagePost, setPagePost] = useState(1);
  const [pagTotal, setPagTotal] = useState();
  const [post, setPost] = useState(null);
  const [stylePgn, setStylePgn] = useState(true);
  const [towers, setTowers] = useState(1);

  useEffect(() => {
    let url = towers == 1 ? 'get_layout' : 'get_residences';

    http.get(`${url}?page=${pagePost}`)
      .then((response) => {
        const postData = towers === 1 ? response.data.Layout : response.data[towers - 2].layout;
        setPost(postData);
        setPagTotal(response.data.count);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [pagePost, towers]);

  const stylePaginate = (e) => {
    setStylePgn(e);
  };

  const onUpdateCurrentPage = async (page) => {
    await setPagePost(page);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <ScrollToTop>
        <Header header={false} />
        <Main post={post} stylePaginate={stylePaginate} towers={towers} setTowers={setTowers} />
        <div>
          <Pagination
            total={pagTotal}
            currentPage={pagePost}
            onChangeCurrentPage={onUpdateCurrentPage}
            dNone={stylePgn}
          />
        </div>
        <Footer />
      </ScrollToTop>
    </div>
  );
}
