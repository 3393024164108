import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import http from '../../http'

import './newsById.css'
import News from '../News/News'
import Carousel from 'react-multi-carousel'
import Moment from 'react-moment'

export default function NewsById({news}) {
  const [posts, setPosts] = useState([])
  useEffect(() => {
    http.get(`get_news?page=${1}`)
      .then((response) => {
        const formatData = [...response.data.news]
        formatData.length = 3
        setPosts(formatData)
      })
  }, [])

  return (
    <div>
      <div className="news-byId-section news-byId page-wrapper">
        <div className="page-top about-wrapper_center">
          <h2 className="page-title">
            Новости
          </h2>
          <p className="breadcrumbs">
            <Link to="/">Главная</Link>/новости
          </p>
        </div>
        <div className="news-block">
          <div className="news__img">
            <img src={news?.map((el) => el.img)} alt=""/>
          </div>
          <div className="news-content">
            <div className="news__wrapper-about">
              <p className="news__title-text">
                {news?.map((el) => el.title)}
              </p>
              <p className="news__text">
                {ReactHtmlParser(news?.map((el) => el.text))}
              </p>
              {/* <p className="news__text">Друзья, мы с радостью сообщаем Вам об открытии офиса продаж ЖК "Freedom Residence"!</p> */}
              {/* <p className="news__text">Индивидуальный офис продаж создан для максимального удобства покупателей и экономии их времени: всего за один визит можно оценить расположение ЖК "Freedom Residence" и его окружение, изучить все презентационные материалы, получить профессиональную консультацию менеджера и подобрать квартиру по своему запросу.</p> */}
              {/* <p className="news__text">Добро пожаловать в "Freedom Residence" - место, где красота и удобство, качество и уют, безопасность и беззаботность никогда не будут спорить между собой.</p> */}
            </div>
          </div>
        </div>

        <div className="news__last">
          <div className="news-header">
            <p>новости</p>
            <div className="projects-inner">
              <span>Другие новости</span>
            </div>
          </div>
          <div className="news-last-elements">
            <div className="news-cards">
              {posts?.map((item, index) => {
                return (
                  <div className="wrapper-news-card overflow-hidden" key={index}>
                    <Link to={`/news/${item.id}`}>
                      <div className="news-card">
                        <h2>{item.title}</h2>
                        <span className="text__news-t">{ReactHtmlParser(item.text.substr(0, 55) + '...')}</span>
                        <p className="news-dates"><Moment format="LL">{item.date}</Moment></p>
                      </div>
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
