import React from 'react'
import Logo from './components/logo/Logo'
import Menu from './components/menu/Menu'
import MobileMenu from './components/menu/mobileMenu/mobileMenu'

import './Header.css'

export default function Header({mainPage}) {
  // window.onscroll = function (e) {
  //   const nav = document.querySelector('.navbar')
  //   if (window.scrollY > 0) {
  //     nav.classList.add('affix')
  //   }
  //   if (header === true) {
  //     if (window.scrollY === 0) {
  //       nav.classList.remove('affix')
  //     }
  //   }
  // }

  const cls = [
    'header',
    mainPage ? 'header-main-page' : ''
  ]

  return (
    <>
      <div className={cls.join(' ')}>
        <nav
          className={'navbar navbar-expand-lg bg-transparent'}>
          <div className="containert head-m">
            <Logo/>
            <Menu/>
            <MobileMenu/>
          </div>
        </nav>
      </div>
    </>
  )
}
