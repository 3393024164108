import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import Moment from 'react-moment'
import 'moment-timezone'
import 'moment/locale/ru'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import './News.css'
import BannerItem from '../banner/BannerItem/BannerItem'
import Group from '../../assets/img/Group.svg'
import operator from '../../assets/img/operator1.svg'
import frames from '../../assets/img/Frame.svg'
import ButtonMore from '../UI/ButtonMore/ButtonMore'

const responsive = {
  desktop: {
    breakpoint: {max: 3000, min: 1024},
    items: 3,
    partialVisibilityGutter: 10 // this is needed to tell the amount of px that should be visible.
  },
  tablet: {
    breakpoint: {max: 1024, min: 464},
    items: 2,
    partialVisibilityGutter: 10 // this is needed to tell the amount of px that should be visible.
  },
  mobile: {
    breakpoint: {max: 590, min: 0},
    items: 1,
    partialVisibilityGutter: 10 // this is needed to tell the amount of px that should be visible.
  }
}

export default function News({news}) {

  return (
    <>
      <div className="top__news">
        <div className="projects-wrapper">
          <div className="news-header">
            <p>новости</p>
            <div className="projects-inner">
              <span>Будьте в курсе наших последних новостей</span>
            </div>
          </div>
        </div>
      </div>
      <div className="news">
        <div className="projects-wrapper">

          <div>
            <div className="news-slides">
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={false}
                autoPlay={true}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                transitionDuration={500}
                containerClass="news-container"
                removeArrowOnDeviceType={['tablet', 'mobile']}
                deviceType={true}
                dotListClass="custom-dot-list-style"
                itemClass="">
                {
                  news.map((item, index) => {
                    return (
                      <div className="wrapper-news-card overflow-hidden" key={index}>
                        <Link to={`/news/${item.id}`}>
                          <div className="news-card">
                            <h2>{item.title}</h2>
                            <span className="text__news-t">{ReactHtmlParser(item.text.substr(0, 60) + '...')}</span>
                            <p className="news-dates"><Moment format="LL">{item.date}</Moment></p>
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </Carousel>
            </div>
          </div>
          <Link to="/news">
            <ButtonMore className="news-more">
              Все новости
            </ButtonMore>
          </Link>
        </div>
      </div>
    </>
  )
}