import React, { useState } from "react";
import { Modal, Form, Toast } from "react-bootstrap";
import axios from "axios";
import FormData from "form-data";
import validation from "./validateModal";
import ReactHtmlParser from "react-html-parser";
import "./Index.css";

export default function Index(props) {
  const [validetAlert, setValidetAlert] = useState(null);
  const [show, setShow] = useState(false);
  const [res, setRes] = useState(null);
  const [form, setForm] = useState({
    name: "",
    phone: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleModalInput = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (!valideModal()) {
      return;
    }

    const data = new FormData();
    data.append("site", "armon.tj");
    data.append("theme", `Заказать звонок - ${props.title}`);
    data.append("name", form.name);
    data.append("phone", form.phone);

    setIsSubmitting(true);

    try {
      const response = await axios.post(
        `https://armon.tj/freedom/telegramForm/php/send-message-to-telegram.php`,
        data
      );
      setRes(response.data);
      resetForm();
    } catch (error) {
      console.error("Ошибка", error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const valideModal = () => {
    const err = validation.modalValidation(form);
    if (err.error) {
      setValidetAlert(err.message);
      setShow(true);
      return false;
    }
    return true;
  };

  const resetForm = () => {
    setForm({
      name: "",
      phone: "",
    });
  };

  return (
    <div className="modal__wrapper">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <p className="text-center text__modal">Оставьте заявку</p>
          {res === null ? "" : ReactHtmlParser(res)}
          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={3000}
            autohide
          >
            <Toast.Body>{validetAlert}</Toast.Body>
          </Toast>
          <p className="text-center text__modal-fdb">
            Оставьте свои контакты и наш менеджер свяжется с Вами
          </p>
          <Form onSubmit={submitForm}>
            <Form.Group controlId="formBasicName">
              <Form.Control
                name="name"
                className="input__modal"
                onChange={handleModalInput}
                placeholder="Имя..."
                type="text"
                value={form.name}
              />
            </Form.Group>
            <Form.Group controlId="formBasicPhone">
              <Form.Control
                name="phone"
                className="input__modal"
                placeholder="Телефон..."
                onChange={handleModalInput}
                type="text"
                value={form.phone}
              />
            </Form.Group>
            <div className="modal__button form-group">
              <input
                className="btn btn-primary modal__btn d-flex justify-content-center"
                type="submit"
                value={isSubmitting ? "Отправка..." : "Оставить заявку"}
                disabled={isSubmitting}
              />
              <button
                type="button"
                onClick={() => props.onHide()}
                className="modal__btn modal__btn--close"
              >
                Закрыть
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
