import React from 'react'
import './Card.css'

function Card({job}) {

  return (
    <div className="mb-4 mx-0">
      <div className="job-card mx-auto">
        <div className="job-img">
          <img src={job.img} alt=""/>
        </div>
        <div className="job-info">
          <p className="job-type">{job.type}</p>
          <p className="job-title">{job.job}</p>
          <p className="job-text">{job.text}</p>
          <p></p>
        </div>
      </div>
    </div>
  )
}

export default Card