import React from 'react'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import Main from '../components/career/Index'

export default function Career() {
    return (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}>
            <Header header={false} />
            <Main />
            <Footer />
        </div>
    )
}
