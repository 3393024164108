import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './Index.css'

import ReactHtmlParser from 'react-html-parser'

import projectsImg1 from '../../assets/img/projects-1.jfif'
import projectsImg2 from '../../assets/img/projects-2.jfif'
import projectsImg3 from '../../assets/img/29.1.jpg'
import Card from '../salesoffices/sales-card/Card'

export default function Index({projects}) {
  const [state, setState] = useState([])

  return (
    <div style={{
      flexGrow: 1
    }}>
      <div className="ofices-section page-wrapper">
        <div className="page-top about-wrapper_center">
          <h2 className="page-title">
            Проекты
          </h2>
          <p className="breadcrumbs">
            <Link to="/">Главная</Link>/проекты
          </p>
        </div>
        <div className="sales-elements">
          {projects.map(project =>
            <Card project={project}/>
          )}
        </div>
      </div>
    </div>
  )
}
