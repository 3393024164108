import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import Moment from 'react-moment'
import './Card.css'
import { Link } from 'react-router-dom'

function Card({news}) {
  return (
    <div className="news-element-card">
      <Link to={`/news/${news.id}`}>
        <p className="news-card-title">{news.title}</p>
        <p className="news-card-text">{ReactHtmlParser(news.text.substr(0, 55))}</p>
        <Moment locale="ru" format="DD MMMM YYYY">
          {news.data}
        </Moment>
      </Link>
    </div>
  )
}

export default Card