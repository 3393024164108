import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
// import moment from 'moment'
import ReactHtmlParser from "react-html-parser";
import "moment-timezone";
// import 'moment/locale/ru';
import "./Index.css";
import Card from './Card/Card'

export default function Index({ news }) {
  console.log(news)
  return (
    <>
      <div className="news-section page-wrapper">
          <div className="page-top about-wrapper_center">
            <h2 className="page-title">
              Новости
            </h2>
            <p className="breadcrumbs">
              <Link to="/">Главная</Link>/Новости
            </p>
          </div>

          <div className="news-elements">
            {news?.map((news, index) =>
              <Card key={news.id} news={news} />
            )}
          </div>
      </div>
    </>
  );
}
