import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import ReactHtmlParser from 'react-html-parser'
import './Projects.css'
import { Link } from 'react-router-dom'
import ButtonMore from '../UI/ButtonMore/ButtonMore'

export default function Projects({projects}) {

  return (
    <>
      <div className="projects">
        <div className="projects-wrapper">
          <div className="projects-header">
            <p>Наши проекты</p>
            <div className="projects-inner">
              <span>Узнайте подробнее о наших проектах </span>
            </div>
          </div>
          <div className="mobile-projects-cards">
            {
              projects.map((item, index) => {
                return (
                  <a href={item.url} target="_blank" key={index}>
                    <div className="wrapper-card">
                      <div className="project-card">
                        <img src={item.img} alt=""/>
                        <div className="project-info">
                          <span className="project-address">{item.address}</span>
                          <h2>{item.title}</h2>
                          <span
                            className="text__projects-tr">
                              {ReactHtmlParser(item.text.substr(0, 196).trim() + '...')}
                            </span>
                        </div>
                      </div>
                    </div>
                  </a>
                )
              })
            }
          </div>
          <Link to="/projects">
            <ButtonMore className="projects-more">
              Все проекты
            </ButtonMore>
          </Link>
        </div>
      </div>
    </>
  )
}
