import React from 'react'
import './Card.css'

function Card2({project}) {
  const soon = project.title === 'Скоро в Продаже'

  console.log(soon)

  return (
    <div className="mb-4 mx-0">
      <div className="sales-card mx-auto">
        <div className="sales-img">
          <img src={project.img} alt=""/>
        </div>
        <div className="sales-info">
          <p className="sales-address">{!soon && project.address}</p>
          <p className="sales-name">{project.title}</p>
          {!soon && <ul className="sales-info-list">
					<li>
              <span className="sales-subtitle">Наш номер: </span>
              <span><a href={`tel:${project.link1}`}>{project.number1}</a></span>, &nbsp;
							<span><a href={`tel:${project.link2}`}>{project.number2}</a></span>
            </li>
            <li>
              <span className="sales-subtitle">Рабочее время: </span>
              <span className="d-block">{project.workTime}</span>
            </li>
            <li>
              <span className="sales-subtitle">Наша почта: </span>
              <span><a href="mailto:sales@armon.tj">{project.email}</a></span>
            </li>
          </ul>}
        </div>
      </div>
    </div>
  )
}

export default Card2