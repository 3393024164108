import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from '../modal/Index'

import './Index.css'

import btnsvg from '../../assets/img/t-inbtn.svg'
import imgtin from '../../assets/img/paym.png'
import imgtin2 from '../../assets/img/paym2.png'

export default function Index() {
  const [modalShow, setModalShow] = useState(false)

  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <div className="payment-section page-wrapper">
        <div className="page-top about-wrapper_center">
          <h2 className="page-title">
            Рассрочка
          </h2>
          <p className="breadcrumbs">
            <Link to="/">Главная</Link>\новости
          </p>
        </div>
        <div className="payment-main-wrapper">
          <div className="payment-main">
            <div className="payment-wrapper">
              <p className="payment__text-title">Рассрочка от застройщика</p>
              <div className="payment__text">
                <p>В отношении рассрочки все намного проще - Вам всего лишь необходимо внести предоплату <span
                  className="blue">30%</span> от стоимости квартиры, а остальные <span
                  className="blue">70%</span> будут рассчитаны на оставшиеся месяцы.
                  Одной из главных преимуществ рассрочки состоит в отсутствии залогов и дополнительных процентов.
                </p>
              </div>
              <button
                className="payment__btn"
                onClick={() => setModalShow(true)}>
                Оставить заявку
              </button>
            </div>
            <div className="payment-img">
              <img src={imgtin} alt="imgtin"/>
            </div>
          </div>
        </div>
        <div className="payment-main-wrapper">
          <div className="payment-main">
            <div className="payment-wrapper">
              <p className="payment__text-title">Ипотека через банк</p>
              <div className="payment__text">
                <p>В отношении рассрочки все намного проще - Вам всего лишь необходимо внести предоплату <span
                  className="blue">30%</span> от стоимости квартиры, а остальные <span
                  className="blue">70%</span> будут рассчитаны на оставшиеся месяцы.
                  Одной из главных преимуществ рассрочки состоит в отсутствии залогов и дополнительных процентов.
                </p>
              </div>
              <button
                className="payment__btn"
                onClick={() => setModalShow(true)}>
                Остались вопросы?
              </button>
            </div>
            <div className="payment-img">
              <img src={imgtin2} alt="imgtin"/>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}
