import React from 'react'
import { Link } from 'react-router-dom'
import './Index.css'

import freedom from '../../assets/img/seles-offices-freedon.jpg'
import oficesImg from '../../assets/img/Rectangle.png'
import scoro from '../../assets/img/29.1.jpg'
import Card from './sales-card/Card'
import Card2 from './sales-card/Card2'


export default function Index({projects}) {

  // console.log(projects)

  return (
    <>
      <div className="ofices-section page-wrapper">
        <div className="page-top about-wrapper_center">
          <h2 className="page-title">
            Офисы продаж
          </h2>
          <p className="breadcrumbs">
            <Link to="/">Главная</Link>/Офисы продаж
          </p>
        </div>
        <div className="sales-elements">
          {projects.map(project =>
            <Card2 project={project}/>
          )}
        </div>

        {/*<div className="row">*/}
        {/*  <div className="col-lg-4 col-md-6">*/}
        {/*    <div className="ofices-card">*/}
        {/*      <div className="card__img">*/}
        {/*        <a href="https://arc.tj/project/ispechak-residence/">*/}
        {/*          <img src={oficesImg} alt=""/>*/}
        {/*        </a>*/}
        {/*      </div>*/}
        {/*      <p className="card__title-text"><a href="https://arc.tj/project/ispechak-residence/">Ispechak*/}
        {/*        Residence</a></p>*/}
        {/*      <p className="card__text">Адреc:<br/>Испечак 2 М14</p>*/}
        {/*      <p className="card__text">Наш номер:<br/><a href="tel:+992987229000">+992 987 22 9000</a></p>*/}
        {/*      <p className="card__text">Рабочее время:<br/> Пон-Суб: 08:00–19:00, Вcк: 10:00-17:00 <br/>Наша почта: <a*/}
        {/*        href="mailto:info@armon.tj">sales@armon.tj</a></p>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="col-lg-4 col-md-6">*/}
        {/*    <div className="ofices-card">*/}
        {/*      <div className="card__img">*/}
        {/*        <a href="https://armon.tj/freedom/">*/}
        {/*          <img src={freedom} alt=""/>*/}
        {/*        </a>*/}
        {/*      </div>*/}
        {/*      <p className="card__title-text"><a href="https://armon.tj/freedom/">Freedom Residence</a></p>*/}
        {/*      <p className="card__text">Адреc:<br/>ул. Н. Махсум 156 (102-й мкр)</p>*/}
        {/*      <p className="card__text">Наш номер:<br/><a href="tel:+992987229000">+992 987 22 9000</a></p>*/}
        {/*      <p className="card__text">Рабочее время:<br/> ПН-СБ - с: 08:00 до 19:00, ВСК - выходной <br/>Наша*/}
        {/*        почта: <a href="mailto:info@armon.tj">sales@armon.tj</a></p>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="col-lg-4 col-md-6">*/}
        {/*    <div className="ofices-card">*/}
        {/*      <div className="card__img">*/}
        {/*        <img src={scoro} alt=""/>*/}
        {/*      </div>*/}
        {/*      <p className="card__title-text">Скоро начнем!</p>*/}
        {/*      <p className="card__text mb-4">Следите за нашими обновлениями</p>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </>
  )
}
