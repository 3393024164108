import React from 'react'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import Main from '../components/salesoffices/Index'

import projectsImg1 from '../assets/img/projects-1.jfif'
import projectsImg2 from '../assets/img/projects-2.jfif'
import projectsImg3 from '../assets/img/29.1.jpg'
import project      from '../assets/img/project.jpg'

export default function SalesOffices() {
    const projects = [
        {
            img: project,
            title: "Legend Towers",
            text: "Один из первых проектов который мы реалезовали в Душанбе.",
						number1: '+992 552 82 82 82',
						link1: '+992552828282',
						number2: '+992 555 82 82 82',
						link2: '+992555828282',
						workTime: 'Пон-Суб: 8:00-19:00, Вск: 10:00-17:00',
						email: 'sales@armon.tj',
            address: "адрес: ул. Шамси, 18"
        },
        {
            img: projectsImg2,
            title: "Freedom Residence",
            text: "Один из первых проектов который мы реалезовали в Душанбе.",
						workTime: 'Пон-Суб: 8:00-19:00, Вск: 10:00-17:00',
						number1: '+992987229000',
						link1: '+992 987 22 90 00',
						number2: '+992110220000',
						link2: '+992 110 22 00 00',
						email: 'sales@armon.tj',
            address: "Испечак 2, м М14"
        },
        {
            img: projectsImg3,
            title: "Скоро в Продаже",
            text: "Один из первых проектов который мы реалезовали в Душанбе.",
						workTime: 'saaad',
						number1: 'asd',
						email: 'aaaa',
            address: "Испечак 2, м М14"
        },

    ];
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh'
        }}>
            <Header header={false} />
            <Main projects={projects} />
            <Footer />
        </div>
    )
}
