import React, { useState } from 'react'
import Modal from '../modal/Index'
import './Index.css'

import btnsvg from '../../assets/img/t-inbtn.svg'
import { Link } from 'react-router-dom'

export default function Index() {
  const [modalShow, setModalShow] = useState(false)
  return (
    <>
      <div className="feedback__wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="feedback-main justify-content-center">
                <div className="text__feedback">
                  <p>Затрудняетесь с выбором? Мы ответим на ваши вопросы!</p>
                  <span>Оставьте свои данные и наши консультанты свяжутся с вами в ближайшее время.</span>
                </div>
              </div>
            </div>
            <div className="col-lg-5 mt-5 mt-lg-0">
              {/*<button onClick={() => setModalShow(true)}>*/}
              {/*  <img src={btnsvg} alt="t-inbtn"/>*/}
              {/*  получить консультацию*/}
              {/*</button>*/}
              <button
                onClick={() => setModalShow(true)}
                className="feedback-button ml-lg-auto mr-lg-0 mr-auto"
              >
                получить консультацию
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  )
}
