import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
import Home from "./pages/Home";
import About from "./pages/About";
import SalesOffices from "./pages/SalesOffices";
import Contacts from "./pages/Contacts";
import News from "./pages/News";
import NewsID from "./pages/NewsID";
import Career from "./pages/Career";
import TradeIn from "./pages/Tradein";
import Payment from "./pages/Payment";
import Projects from "./pages/Projects";
import Team from "./pages/Team";
import Layouts from "./pages/Layouts";
import NotFound from "./pages/404.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./styles/styles.css";

function App() {
  return (
    <Router basename="#">
      <ScrollToTop>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" component={About} />
          <Route path="/sales-offices" component={SalesOffices} />
          <Route path="/contacts" component={Contacts} />
          <Route path="/news" exact component={News} />
          <Route path="/news/:slug" component={NewsID} />
          <Route path="/career" component={Career} />
          <Route path="/tradein" component={TradeIn} />
          <Route path="/payment" component={Payment} />
          <Route path="/projects" component={Projects} />
          <Route path="/team" component={Team} />
          <Route path="/onsales" component={Layouts} />
          <Route path="*" component={NotFound} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default App;
