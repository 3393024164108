import React from 'react'
import './Card.css'

function Card({project}) {
  const soon = project.title === 'Скоро в Продаже'

  // console.log(soon)

  return (
    <div className="mb-4 mx-0">
      <div className="sales-card mx-auto">
        <div className="sales-img">
          <img src={project.img} alt=""/>
        </div>
        <div className="sales-info">

          <p className="sales-address">{!soon && project.address}</p>
          <p className="sales-name">{project.title}</p>
          {!soon && <ul className="sales-info-list">
					{project.title == 'Freedom Residence' ? 
					<li>
					<span className="sales-subtitle">Наш номер: </span>
					<span><a href="tel:+992110220000">+992 110 22 00 00</a></span>, &nbsp;
					<span><a href="tel:+992987229000">+992 987 22 90 00</a></span>
				</li> : 
				<li>
				<span className="sales-subtitle">Наш номер: </span>
				<span><a href="tel:+992552828282">+992 552 82 82 82</a></span>, &nbsp;
				<span><a href="tel:+992555828282">+992 555 82 82 82</a></span>
			</li>}
            <li>
              <span className="sales-subtitle">Рабочее время: </span>
              <span className="d-block">Пон-Суб: 8:00-19:00, Вск: 10:00-17:00 </span>
            </li>
            <li>
              <span className="sales-subtitle">Наша почта: </span>
              <span><a href="mailto:sales@armon.tj">sales@armon.tj</a></span>
            </li>
          </ul>}
        </div>
      </div>
    </div>
  )
}

export default Card